import styled from "styled-components"
import { NavToggle, NavUntoggle } from "@mostlycode/ui"

const StyledNavToggle = styled(NavToggle)`
  border: none;
  background: ${(props) => props.theme.colors.secondary};
  transition: all ease-out 0.2s;
  color: ${(props) => props.theme.colors.white};
  border-radius: 50%;

  .headroom--pinned & {
    // color: ${(props) => props.theme.colors.primary};
  }

  &:active {
    background: none;
  }

  &:focus {
    outline: none;
    background: none;
  }
`

const StyledNavUntoggle = styled(NavUntoggle)`
  border: none;
  background: ${(props) => props.theme.colors.secondary};
  transition: all ease-out 0.2s;
  color: ${(props) => props.theme.colors.white};
  border-radius: 50%;
  margin: 0;

  &:focus {
    outline: none;
  }
`

export { StyledNavToggle, StyledNavUntoggle }
