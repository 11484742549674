import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import linkResolver from "utils/linkResolver"
import { SiteContext } from "context/SiteContext"
import SocialMediaLinks from "components/SocialMediaLinks"

const StyledNavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 1em 0;
    position: relative;
    a {
      color: ${(props) => props.theme.colors.white};
      text-decoration: none;
      font-size: 2.4rem;
      font-family: ${(props) => props.theme.fontStacks.subheading};
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      border-right: 3px solid transparent;
      &.active {
        color: ${(props) => props.theme.colors.sand};
        border-right: 3px solid currentColor;
      }
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    display: flex;
    align-items: center;

    li {
      margin: 0 0 0 4rem;
      a {
        // color: ${(props) => props.theme.colors.primary};
        font-size: 2rem;
        letter-spacing: 0.1rem;
        border-bottom: 3px solid transparent;
        border-right: none;
        &.active {
          color: ${props.theme.colors.sand};
          border-bottom: 3px solid currentColor;
          border-right: none;
        }
      }
    }
  `}
`

const StyledSocialMediaLinks = styled.li`
  display: flex;
  justify-content: flex-end;

  div {
    flex: 0 1 10rem;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    width: 10rem;
    svg {
      fill: ${(props) => props.theme.colors.white};
    }
  `}
`

function NavigationList({ social_media_links, items }) {
  const {
    state: { authenticated },
  } = useContext(SiteContext)

  return (
    <StyledNavList>
      {authenticated ? (
        <li>
          <Link to="/app" activeClassName="active">
            Partnerprofil
          </Link>
        </li>
      ) : (
        <>
          <li>
            <Link to="/app/login" activeClassName="active" partiallyActive>
              Log Ind
            </Link>
          </li>
        </>
      )}
      <li>
        <Link
          to="/om-danmarksopsamling"
          activeClassName="active"
          partiallyActive
        >
          Om os
        </Link>
      </li>
      {/* <li>
        <Link to="/find-initiativ" activeClassName="active" partiallyActive>
          Find initiativ
        </Link>
      </li> */}
      {/* <li>
        <Link to="/til-virksomheder" activeClassName="active" partiallyActive>
          Til virksomheder
        </Link>
      </li> */}
      <li>
        <Link to="/blog" activeClassName="active" partiallyActive>
          Blog
        </Link>
      </li>
      <li>
        <Link to="/kontakt" activeClassName="active" partiallyActive>
          Kontakt
        </Link>
      </li>
      <StyledSocialMediaLinks>
        <SocialMediaLinks links={social_media_links} />
      </StyledSocialMediaLinks>
    </StyledNavList>
  )
  // renderNavigationItems(items)
}

export default NavigationList
