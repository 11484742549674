import { colors } from './colors'

const buttons = {
  primary: {
    background: colors.primary,
    color: colors.white,
    border: "none",
  },
  secondary: {
    background: colors.secondary,
    color: colors.white,
    border: "none",
  },
  danger: {
    background: colors.coral,
    color: colors.white,
    border: "none",
  },
  default: {
    background: colors.coral,
    color: colors.white,
    border: "none",
  },
  transparent: {
    background: `transparent`,
    color: "currentColor",
    border: "none",
  },
}

export { buttons }
