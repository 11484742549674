import { useContext, useEffect } from "react"
import { auth } from "services/auth"
import { SiteContext } from "context/SiteContext"
import { setAuthenticated } from "actions/site"

export function useAuth() {
  const {
    state: { authenticated, ...rest },
    dispatch,
  } = useContext(SiteContext)

  async function isAuthenticated() {
    try {
      const response = await auth()
      if (response.data.status === "OK") {
        dispatch(setAuthenticated(true))
      } else {
        dispatch(setAuthenticated(false))
      }
    } catch (e) {
      dispatch(setAuthenticated(false))
    }
  }

  useEffect(() => {
    if (authenticated === null) isAuthenticated()
  }, [])

  return authenticated
}
