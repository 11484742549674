import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic-graphql"
import { metaResolver } from "utils/seoResolver"
import Facebook from "./Facebook"
import Twitter from "./Twitter"

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEOComponent = ({
  title,
  description,
  author,
  slug,
  lang,
  images,
  siteUrl,
  metaTitlePrefix,
  metaTitleAppendix,
  logo,
  facebook,
  twitter,
  hideFromGoogle,
  ...props
}) => {
  const {
    data: {
      site: { defaultLocalMeta },
      prismic: { defaultMeta },
    },
  } = props

  const seoImages = images[0]?.image
    ? images
    : [{ image: defaultMeta.defaultImages }]

  const seo = metaResolver(
    {
      title,
      description,
      author,
      slug,
      lang,
      images: seoImages,
      siteUrl,
      metaTitlePrefix,
      metaTitleAppendix,
      logo,
      facebook,
      twitter,
    },
    {
      ...defaultMeta,
      ...defaultLocalMeta,
    }
  )

  const singleImage = `${seo.siteUrl}${
    seo.mainImage?.imageSharp?.childImageSharp?.main?.src ||
    seo.mainImage?.image?.url
  }`

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={seo.siteLang} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={`${singleImage}`} />
        <link rel="canonical" href={`${seo.siteUrl}${seo.slug}`} />
        {(hideFromGoogle || process.env.GATSBY_ACTIVE_ENV !== "production") && (
          <meta name="robots" content="noindex,nofollow" />
        )}
      </Helmet>
      {seo.images.length ? (
        seo.images.map(({ image, imageSharp }, idx) => {
          return (
            <Facebook
              desc={seo.description}
              title={seo.title}
              type="website"
              url={`${seo.siteUrl}${seo.slug}`}
              locale={seo.ogLang}
              name={facebook}
            >
              <meta
                key={`meta_img_${idx}`}
                property="og:image"
                content={`${seo.siteUrl}${
                  imageSharp?.childImageSharp?.main?.src || image?.url
                }`}
              />
              <meta
                key={`meta_img_alt_${idx}`}
                property="og:image:alt"
                content={image?.alt}
              />
            </Facebook>
          )
        })
      ) : (
        <Facebook
          desc={seo.description}
          title={seo.title}
          type="website"
          url={`${seo.siteUrl}${seo.slug}`}
          locale={seo.ogLang}
          name={facebook}
        >
          <meta
            property="og:image"
            content={`${seo.siteUrl}${seo.images[0]?.image.url}`}
          />
          <meta property="og:image:alt" content={`${seo.images?.alt}`} />
        </Facebook>
      )}
      <Twitter
        title={seo.title}
        siteUrl={seo.siteUrl}
        image={singleImage}
        desc={seo.description}
        username={twitter}
      />
    </>
  )
}

const seoQuery = graphql`
  {
    site {
      defaultLocalMeta: siteMetadata {
        defaultLang: lang
        defaultSiteUrl: siteUrl
        defaultFacebook: facebook
        defaultTwitter: twitter
      }
    }
    prismic {
      defaultMeta: site_settings(lang: "da-dk", uid: "site-settings") {
        defaultImages: default_meta_image
        defaultDescription: default_meta_description
        defaultTitle: default_meta_title
        defaultMetaTitleAppendix: meta_title_appendix
        defaultMetaTitlePrefix: meta_title_prefix
      }
    }
  }
`

const SEO = (props) => (
  <StaticQuery
    query={seoQuery}
    render={withPreview(
      (data) => (
        <SEOComponent data={data} {...props} />
      ),
      seoQuery
    )}
  />
)

export default SEO

SEOComponent.defaultProps = {
  title: null,
  description: null,
  author: null,
  slug: null,
  lang: null,
  siteUrl: null,
  metaTitlePrefix: null,
  metaTitleAppendix: null,
  logo: null,
  facebook: null,
  twitter: null,
  images: [],
}

SEOComponent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  slug: PropTypes.string,
  lang: PropTypes.string,
  siteUrl: PropTypes.string,
  metaTitlePrefix: PropTypes.string,
  metaTitleAppendix: PropTypes.string,
  logo: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
}
