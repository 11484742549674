/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import { GlobalStyles, FontFamilies } from "@mostlycode/ui/theme"
import { RiftCustomFont } from "theme/fonts/rift.css"
import { theme } from "theme"
import CustomGlobalStyles from "theme/customGlobalStyles"
import styled, { ThemeProvider } from "styled-components"

import { NavigationContext, ViewportContext } from "context"
import { isViewport } from "helpers"
import useViewport from "hooks/useViewport"

import Header from "components/Header"
import Navigation from "components/Navigation"
import { StyledNavUntoggle } from "components/NavToggle"
import OffCanvasNav from "components/OffCanvasNav"
import Footer from "components/Footer"
import { useAuth } from "hooks/useAuth"

const StyledMain = styled.main`
  position: relative;
`

const Layout = ({ site_settings, noHeader, children }) => {
  const authenticated = useAuth()
  const [navToggled, setNavToggled] = useState(false)
  const viewport = useViewport()

  function handleNavToggle() {
    setNavToggled((prev) => !prev)
  }

  return (
    <ThemeProvider theme={theme}>
      <RiftCustomFont />
      <FontFamilies />
      <GlobalStyles />
      <CustomGlobalStyles />
      <ViewportContext.Provider value={viewport}>
        <NavigationContext.Provider value={navToggled}>
          <StyledMain>
            <Header onNavToggle={handleNavToggle}>
              <Navigation
                social_media_links={site_settings?.social_media_links}
              />
            </Header>
            {children}
          </StyledMain>
          <Footer
            copyright_information={site_settings?.copyright_information}
            footer_text={site_settings?.footer_text}
            footer_info={site_settings?.footer_info}
            social_media_links={site_settings?.social_media_links}
          />
          {isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) && (
            <OffCanvasNav
              navToggle={<StyledNavUntoggle onToggle={handleNavToggle} />}
              nav={
                <Navigation
                  social_media_links={site_settings?.social_media_links}
                />
              }
            ></OffCanvasNav>
          )}
        </NavigationContext.Provider>
      </ViewportContext.Provider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
