import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { OffCanvas, Icon } from "@mostlycode/ui"
import { NavigationContext } from "context"
import { useLockBodyScroll } from "hooks/useLockBodyScroll"

const StyledOffCanvasNavigation = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 4rem 2rem;
  grid-template-rows: 2rem 4rem 1fr 16rem;
  grid-template-areas:
    ". . ."
    "logo navtoggle ."
    "navigation navigation ."
    "visuals visuals visuals";
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  position: relative;
`

const StyledNavToggle = styled.div`
  grid-area: navtoggle;
  // padding: 2rem 2rem 0;
`

const StyledNavInner = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  grid-area: navigation;
  text-align: right;
`

const StyledFooter = styled.footer`
  grid-area: visuals;
  position: relative;
`

const StyledWave = styled(Icon)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 68vw;
  transform: rotateY(180deg);
  z-index: 1000;
  fill: ${(props) => props.theme.colors.sand};
`

const StyledCoral = styled(Icon)`
  position: absolute;
  bottom: 2rem;
  right: 4rem;
  z-index: 2000;
  width: 50vw;
  height: 50vw;
  fill: ${(props) => props.theme.colors.secondary};
`

function OffCanvasNav({ navToggle, nav, children }) {
  const toggled = useContext(NavigationContext)
  useLockBodyScroll(toggled)
  return (
    <OffCanvas selector="#___offcanvas" show={toggled} fromRight>
      <StyledOffCanvasNavigation>
        <StyledNavToggle>{navToggle}</StyledNavToggle>
        <StyledNavInner>{nav}</StyledNavInner>
        <StyledFooter>
          <StyledWave icon="wave-large-bottom-right" />
          <StyledCoral icon="coral-trifork" />
        </StyledFooter>
      </StyledOffCanvasNavigation>
    </OffCanvas>
  )
}

export default OffCanvasNav

OffCanvasNav.defaultProps = {
  children: null,
}

OffCanvasNav.propTypes = {
  navToggle: PropTypes.node.isRequired,
  nav: PropTypes.node.isRequired,
  children: PropTypes.node,
}
