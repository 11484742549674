import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Legal from "./Legal"
import InputButton from "components/InputButton"
import Button from "components/Button"
import Input from "components/Input"
import Content from "components/Content"
import Paragraph from "components/Paragraph"
import SocialMediaLinks from "components/SocialMediaLinks"

const StyledFooter = styled.footer`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.primary};
  display: block;
  padding: 5rem 0;

  ${(props) => props.theme.media.tablet_landscape_up`
    text-align: center;
    display: flex;
    justify-content: center;
  `}
`

const StyledParagraph = styled(Paragraph)`
  display: flex;
  align-items: center;
  max-width: 84rem;
  padding-bottom: 1rem;
  flex-direction: column;
`

const StyledCTA = styled.div`
  margin: 4rem 0 0;
`

const StyledSocialMediaLinks = styled(SocialMediaLinks)`
  margin-bottom: 4rem;

  > * {
    margin-bottom: 2rem;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    // margin: 0 auto;
    > * {
      margin-bottom: auto;
    }
    min-width: 10rem;
  `}
`

function Footer({
  copyright_information,
  footer_text,
  footer_info,
  social_media_links,
  ...props
}) {
  return (
    <StyledFooter>
      <StyledParagraph>
        <StyledSocialMediaLinks links={social_media_links} />
        <Content content={footer_text} />
        <StyledCTA>
          <Button
            href="mailto:hej@danmarksopsamling.dk"
            target="_blank"
            rel="noopener noreferrer"
          >
            hej@danmarksopsamling.dk
          </Button>
        </StyledCTA>
        {/* <InputButton
          input={<Input type="email" placeholder="Indtast din email" />}
          button={
            <Button
              href="mailto:hej@danmarksopsamling.dk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tilmeld dig nyhedsbrev
            </Button>
          }
        /> */}
        <Legal
          copyright_text={copyright_information[0].text}
          info={footer_info}
        />
      </StyledParagraph>
    </StyledFooter>
  )
}

export default Footer

export const query = graphql`
  fragment Footer on PRISMIC_Site_settings {
    copyright_information
    footer_text
    footer_info {
      info
    }
    social_media_links {
      icon
      title
      link {
        ... on PRISMIC__ExternalLink {
          target
          url
        }
      }
    }
  }
`
