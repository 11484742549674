import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledLegal = styled.div`
  padding-top: 10rem;
  font-size: 87%;
`

function Legal({ copyright_text, info }) {
  const year = new Date()
  const copyright = `\u{00A9} ${year.getFullYear()}`
  const dot = ` ･ `
  return (
    <StyledLegal>
      <span>{copyright}</span>
      <span>{` ${copyright_text}`}</span>
      {info.map((item, i) => {
        return [
          <span key={`item_dot_${i}`}>{dot}</span>,
          <span key={`item_${i}`}>{item.info}</span>,
        ]
      })}
    </StyledLegal>
  )
}

export default Legal

Legal.defaultProps = {
  copyright_text: "All rights reserved.",
}

Legal.propTypes = {
  copyright_text: PropTypes.string,
}
