import { colors } from './colors'

const tileThemes = {
  blue: {
    bg: colors.primary,
    color: colors.white,
    contrast: colors.coral,
  },
  coral: {
    bg: colors.coral,
    color: colors.white,
    contrast: colors.blue,
  },
  sand: {
    bg: colors.sand,
    color: colors.blue,
    contrast: colors.coral,
  },
  white: {
    bg: colors.white,
    color: colors.primary,
    contrast: colors.coral,
  },
  transparent: {
    bg: `transparent`,
    color: 'currentColor',
  }
}

export { tileThemes }
