import React from "react"
import styled from "styled-components"

const StyledInputButton = styled.form`
  margin: 2rem 0;
  width: 100%;
  > * {
    display: inline-block;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    align-items: center;
    justify-content: center;
    display: flex;
  `}
`

const InputWrapper = styled.div`
  margin-right: 2.5rem;
  width: 100%;

  ${(props) => props.theme.media.tablet_landscape_up`
    width: auto;
    flex: 1;
  `}
`

function InputButton({ input, button, ...props }) {
  return (
    <StyledInputButton {...props}>
      {input && <InputWrapper>{input}</InputWrapper>}
      {button}
    </StyledInputButton>
  )
}

export default InputButton
