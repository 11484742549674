import { createGlobalStyle } from "styled-components"
import { lighten, rgba } from "polished"

const CustomGlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
    background: ${(props) => props.theme.colors.primary};
  }

  * {
    &::selection {
      background: ${(props) => rgba(props.theme.colors.secondary, 0.99)};
      color: ${(props) => props.theme.colors.white};
    }
    img {
      &::selection {
        background: ${(props) => rgba(props.theme.colors.secondary, 0.6)};
      }
    }
  }

  body {
    font-size: ${(props) => props.theme.fontSizes.p}rem;
    background: white;
    position: relative;
    z-index: 100;
  }

  img {
    display: block;
  }

  p > a {
    color: ${(props) => lighten(0.25, props.theme.colors.primary)};
    text-decoration: none;
    border-bottom: 1px dotted ${(props) =>
      lighten(0.25, props.theme.colors.primary)};
    display: inline-block;
    line-height: 1.2;
  }
`

export default CustomGlobalStyles
