import React, { useContext } from "react"
import styled from "styled-components"
import Headroom from "react-headroom"
import Logo from "components/Logo"
import { StyledNavToggle } from "components/NavToggle"
import Navigation from "components/Navigation"
import { ViewportContext } from "context"
import { isViewport } from "helpers"

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4rem;
  min-height: 10rem;
  position: relative;

  nav {
    margin-left: auto;
  }

  .headroom--scrolled & {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.05);
  }

  .headroom--pinned & {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.05);
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    nav {
      padding-top: 2rem;
      margin-bottom: auto;
    }

    .headroom--scrolled &,
    .headroom--pinned & {
      min-height: 0;
      padding: 0.5rem 4rem;
      nav {
        margin-top: auto;
        padding-top: 0;
      }

      a {
        color: ${(props) => props.theme.colors.primary};
        &.active {
          color: ${props.theme.colors.coral};
        }
      }
    }
  `}
`

function Header({ onNavToggle, children, ...props }) {
  const viewport = useContext(ViewportContext)
  return (
    <Headroom style={{ zIndex: "1000" }}>
      <StyledHeader>
        {!isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]) ? (
          <>
            <Logo regular />
            <nav>{children}</nav>
          </>
        ) : (
          <>
            <Logo />
            <nav>
              <StyledNavToggle onToggle={onNavToggle} />
            </nav>
          </>
        )}
      </StyledHeader>
    </Headroom>
  )
}

export default Header
