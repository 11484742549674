import Cookies from 'js-cookie'
import { publicApi, privateApi } from 'utils/api'

const unsetCookies = async () => {
  await Cookies.remove('dkopsamling-token')
  await Cookies.remove("dkopsamling-remember")
  return true
}

const remember = async () => {
  await Cookies.set('dkopsamling-remember', 'true', { expires: 30 })
  return
}

const setAuthentication = (token) => {
  if (token !== "") {
    // User is logged in
    Cookies.get('dkopsamling-remember') === 'true'
      ? Cookies.set('dkopsamling-token', token, { expires: 30 })
      : Cookies.set('dkopsamling-token', token)
    return {
      authenticated: true,
    }
  }
  // User is not logged in
  return {
    authenticated: false,
  }
}

/**
 * Return value expected to be an object with user information
 */

const login = async (email, phone) => {
  try {
    // Check if the user is signed in
    const response = await publicApi().post('/login', { email, phone })
    // Set tokens accordingly and return user data
    return response
  } catch (e) {

  }
}

/**
 * Return value expected to be an object with user information
 */

const logout = async () => {
  try {
    // Check if the user is signed in
    const response = await privateApi().get('/logout')
    if (response.data.status === "OK") {
      await unsetCookies()
    }
    // Set tokens accordingly and return user data
    return response
  } catch (e) {

  }
}

/**
 * Return value expected to be a
 */

const verify = async (user_id, otp) => {
    try {
      // Check if the user is signed in
      const response = await publicApi().post('/verify', { id: user_id, otp })
      setAuthentication(response.data.body.token)
      // Set tokens accordingly and return user data
      return response
    } catch (e) {

    }
}

/**
 * Authenticate current user
 */

const auth = async () => {
    try {
      if (Cookies.get("dkopsamling-token")) {
        // Check if the user is signed in
        const response = await privateApi().get(`/auth`)
        return response
      }
      // Set tokens accordingly and return user data
      return false
    } catch (e) {

    }
}

export { login, logout, verify, auth }
