import { theme as defaultTheme } from '@mostlycode/ui/theme'
import { fonts, fontStacks } from './fonts'
import { colors } from './colors'
import { buttons } from './buttons'
import { tileThemes } from './tiles'
import { fontSizes } from './typography'

const theme = {
  ...defaultTheme,
  colors: colors,
  fonts: fonts,
  fontStacks: fontStacks,
  fontSizes: fontSizes,
  buttonStyles: buttons,
  tileStyles: tileThemes,
  baseBodyColor: colors.blue,
}

export { theme }
