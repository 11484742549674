import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import { Icon } from "@mostlycode/ui"

const StyledLogo = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;

  svg:first-child {
    fill: ${(props) => props.theme.colors.coral};
    width: 6rem;
    height: 6rem;
  }

  svg:last-child {
    fill: ${(props) => props.theme.colors.primary};
    width: auto;
    height: 6rem;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    svg:first-child,
    svg:last-child {
      height: 18rem;
      width: 18rem;
      transition: all .2s ease-out;

      .headroom--scrolled &,
      .headroom--pinned & {
        height: 10rem;
        width: 10rem;
        max-height: 10rem;
        max-width: 10rem;
      }
    }
  `}
`

function Logo({ regular, ...props }) {
  return (
    <StyledLogo to="/" {...props}>
      {regular ? (
        <Icon icon="logo" />
      ) : (
        <>
          <Icon icon="logo-icon" />
          <Icon icon="logo-text" />
        </>
      )}
    </StyledLogo>
  )
}

export default Logo

Logo.defaultProps = {}
Logo.propTypes = {}
