import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import DefaultButton from "@mostlycode/ui/dist/Button"
import { lighten, desaturate } from "polished"

function getStyles(props) {
  return `
    display: inline-block;
    border: ${props.theme.buttonStyles[props.variant].border};
    border-radius: 3rem;
    letter-spacing: 0.1rem;
    transition: all ease-out 0.15s;
    font-size: 2rem;
    font-family: ${props.theme.fontStacks.subheading};
    // line-height: 5rem;
    height: 5rem;
    cursor: pointer;
    // padding: 0 3rem;

    ${
      props.invert
        ? `
          background: ${props.theme.buttonStyles[props.variant].color};
          color: ${props.theme.buttonStyles[props.variant].background};

          &:focus {
            box-shadow: inset 0 0 2px ${desaturate(
              0.1,
              props.theme.buttonStyles[props.variant].background
            )};
          }

        `
        : `
        background: ${props.theme.buttonStyles[props.variant].background};
        color: ${props.theme.buttonStyles[props.variant].color};

        &:focus {
          box-shadow: inset 0 -2px 4px ${
            props.theme.buttonStyles[props.variant].background
          };
        }`
    }

    &:hover:not(:disabled) {
    ${
      props.invert
        ? `
            background: ${lighten(
              0.1,
              props.theme.buttonStyles[props.variant].color
            )};
            color: ${lighten(
              0.1,
              props.theme.buttonStyles[props.variant].background
            )};
          `
        : `
            background: ${lighten(
              0.1,
              props.theme.buttonStyles[props.variant].background
            )};
            color: ${lighten(
              0.1,
              props.theme.buttonStyles[props.variant].color
            )};
          `
    }
  `
}

const StyledLinkButton = styled(Link)`
  text-decoration: none;
  line-height: 5rem;
  padding: 0 3rem;
  ${(props) => getStyles(props)}
`

const StyledButton = styled(DefaultButton)`
  &:focus {
    outline: none;
    box-shadow: ${(props) => props.theme.inputFocusShadow};
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }

  span {
    justify-content: center;
  }

  ${(props) => getStyles(props)}
`

const StyledIconButton = styled(DefaultButton)`
  background: none;
  padding: 0;
  color: ${(props) => props.theme.colors.black};
  margin-right: 0;

  svg {
    color: currentColor;
  }
`

function Button({ children, variant, href, ...props }) {
  const internal = /^\/(?!\/)/.test(href)
  if (href && internal) {
    return (
      <StyledLinkButton variant={variant} to={href} {...props}>
        {children}
      </StyledLinkButton>
    )
  } else if (href) {
    return (
      <StyledLinkButton variant={variant} href={href} as="a" {...props}>
        {children}
      </StyledLinkButton>
    )
  }
  return variant === "icon" ? (
    <StyledIconButton {...props}>{children}</StyledIconButton>
  ) : (
    <StyledButton variant={variant} {...props}>
      {children}
    </StyledButton>
  )
}

export default Button

Button.defaultProps = {
  variant: "default",
  invert: false,
}

Button.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "danger",
    "default",
    "transparent",
    "icon",
  ]),
  invert: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
