import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"
import ArticleHeader from "components/ArticleHeader"
import ArticleBody from "components/ArticleBody"
import { valuable } from "utils/worthy"

const StyledParagraph = styled.article`
  padding: 5rem 2rem 10rem 3rem;
  max-width: 70rem;

  & + & {
    margin-top: -10rem;
    padding: 5rem 2rem 5rem 3rem;
  }

  &:last-child {
    padding: 5rem 2rem 10rem 3rem;
  }

  ${(props) => (props.center ? `margin: 0 auto;` : ``)}

  h4 {
    font-family: ${(props) => props.theme.fontStacks.subheading};
    font-size: 150%;
    margin-bottom: 0;
  }
`

function Paragraph({ content, title, subtitle, children, ...props }) {
  if (content || title || subtitle) {
    return (
      <StyledParagraph center {...props}>
        {valuable(title) || valuable(subtitle) ? (
          <ArticleHeader>
            {valuable(title) && <Content content={title} />}
            {valuable(subtitle) && subtitle[0].text !== "" && (
              <Content content={subtitle} />
            )}
          </ArticleHeader>
        ) : null}
        {valuable(content) && (
          <ArticleBody>
            <Content content={content} />
          </ArticleBody>
        )}
      </StyledParagraph>
    )
  }
  return <StyledParagraph {...props}>{children}</StyledParagraph>
}

export default Paragraph

Paragraph.defaultProps = {}
Paragraph.propTypes = {
  children: PropTypes.node,
}
