import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledArticleBody = styled.section`
  max-width: 60rem;
  margin: 0 auto;

  p {
    &:first-child {
      margin-top: 0;
    }
  }

  svg {
    display: block;
    width: 20rem;
    height: 14.5rem;
    margin: 4rem auto 2rem;
    fill: ${(props) => props.theme.colors.coral};
  }
`

function ArticleBody({ children, ...props }) {
  return <StyledArticleBody {...props}>{children}</StyledArticleBody>
}

export default ArticleBody

ArticleBody.defaultProps = {}
ArticleBody.propTypes = {
  children: PropTypes.node.isRequired,
}
