import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Input as DefaultInput } from "@mostlycode/ui"

const StyledInput = styled(DefaultInput)`
  border-radius: 3rem;
  border: 2px solid ${(props) => props.theme.colors.sand};
  padding: 0 2.5rem;
  line-height: 5rem;

  &:focus {
    outline: none;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

function Input({ ...props }) {
  return <StyledInput {...props} />
}

export default Input

Input.defaultProps = {}
Input.propTypes = {}
