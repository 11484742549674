import axios from 'axios'
import Cookies from 'js-cookie'

/**
 * Establish apiBase and figure out hostname based on environment
 */

const apiHost = () => {
  switch (process.env.GATSBY_ACTIVE_ENV) {
    case "production":
      return "https://api.danmarksopsamling.dk"
    default:
      return "https://staging.api.danmarksopsamling.dk"
  }
}

const apiUrl = `${apiHost()}`

const api = (auth) => {
  const baseConfig = {
    baseURL: apiUrl,
    timeout: 60000,
  }
  const privateConfig = { headers: { 'auth-token': Cookies.get('dkopsamling-token') } }
  const config = Object.assign({}, baseConfig, auth ? { ...privateConfig } : {})
  const instance = axios.create({ ...config })
  return instance
};

const setToken = async (token, remember) => {
  if (token !== "") {
    // User is logged in
    await remember
      ? Cookies.set('dkopsamling-token', token, { expires: 30 })
      : Cookies.set('dkopsamling-token', token)
    return
  }
}

const publicApi = () => api()
const privateApi = () => api(true)

export { apiUrl, publicApi, privateApi, setToken }
