const brand = {
  coral: "#F04F4D",
  blue: "#00263F",
  sand: "#E4DCCA",
  white: "#FFFFFF",
}

const colors = {
  ...brand,
  primary: brand.blue,
  secondary: brand.coral,
  white: brand.white,
}

export { colors }
