const fonts = [
  {
    source: `static`,
    fonts: [
      {
        filename: `gilroy`,
        title: `Gilroy`,
        config: {
          weights: [`medium`],
        },
      },
      {
        filename: `gilroy`,
        title: `Gilroy`,
        config: {
          weights: [`bold`],
        },
      },
    ],
  },
]

const fontStacks = {
  heading: `'Gilroy Bold', sans-serif`,
  body: `'Gilroy Medium', sans-serif`,
  subheading: `'Rift Soft Bold', sans-serif`
}

export { fonts, fontStacks }
