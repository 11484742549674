import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Icon } from "@mostlycode/ui"
import { getIconFromUrl } from "utils/iconResolver"

const StyledSocialMediaLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 4rem);
  grid-column-gap: 2rem;
`

const StyledLink = styled.a`
  background: ${(props) => props.theme.colors.coral};
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
`

function SocialMediaLinks({ links, ...props }) {
  return (
    <StyledSocialMediaLinks {...props}>
      {links.map(({ title, icon, link, ...rest }, i) => {
        const iconFromUrl = getIconFromUrl(icon.url)
        return (
          <StyledLink
            key={`some_link_${i}`}
            href={link.url}
            title={title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon={iconFromUrl} />
          </StyledLink>
        )
      })}
    </StyledSocialMediaLinks>
  )
}

export default SocialMediaLinks

SocialMediaLinks.defaultProps = {
  links: [],
}
SocialMediaLinks.propTypes = {}
