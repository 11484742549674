import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledArticleHeader = styled.header`
  margin: 0 auto;
  max-width: 60rem;

  h1,
  h2 {
    /* text-transform: lowercase;
    text-align: center; */
    display: block;
    position: relative;
    /* padding-bottom: 1em; */
    margin-bottom: 1em;
    max-width: 60rem;
    line-height: 1.2em;
    font-size: 300%;

    /* &:after {
      position: absolute;
      width: 40px;
      height: 6px;
      background: ${(props) =>
        props.colorTheme && props.colorTheme.toLowerCase() === "coral"
          ? props.theme.colors.primary
          : props.theme.colors.secondary};
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    } */
  }

  h3 {
    display: block;
    text-align: left;
    font-family: ${(props) => props.theme.fontStacks.subheading};
    letter-spacing: 0.3rem;
    text-transform: uppercase;
    position: relative;
    // padding-bottom: 1em;
    margin-top: 0;
    margin-bottom: 0.25em;
    max-width: 60rem;
    line-height: 1.2em;

    /* &:after {
      position: absolute;
      width: 40px;
      height: 6px;
      background: ${(props) => props.theme.colors.secondary};
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    } */
  }
`

function ArticleHeader({ children, ...props }) {
  return <StyledArticleHeader {...props}>{children}</StyledArticleHeader>
}

export default ArticleHeader

ArticleHeader.defaultProps = {
  colorTheme: null,
}
ArticleHeader.propTypes = {
  children: PropTypes.node.isRequired,
  colorTheme: PropTypes.string,
}
